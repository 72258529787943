import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c686e104"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "add-transaction-modal-container" }
const _hoisted_2 = {
  key: 0,
  class: "search-existing-container"
}
const _hoisted_3 = { class: "search-container" }
const _hoisted_4 = {
  key: 0,
  style: {"display":"flex"}
}
const _hoisted_5 = { style: {"margin-left":"10px"} }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "error-message" }
const _hoisted_8 = {
  key: 1,
  class: "button-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_modal, {
      modelValue: _ctx.state.showModal,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.showModal) = $event)),
      centered: "",
      title: _ctx.getTitleCaseTranslation('core.button.addStop'),
      "hide-footer": "",
      onHide: _ctx.close
    }, {
      default: _withCtx(() => [
        (_ctx.state.searchExistingSelected)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_b_form, {
                  class: "search-existing-form",
                  onSubmit: _ctx.addExistingTransaction
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_form_input, {
                      modelValue: _ctx.state.searchValue,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.searchValue) = $event)),
                      autofocus: "",
                      type: "text",
                      placeholder: _ctx.getTranslation('core.common.typeOrScanTransactionNumber')
                    }, null, 8, ["modelValue", "placeholder"]),
                    _createVNode(_component_b_button, {
                      size: "sm",
                      variant: "primary",
                      disabled: _ctx.state.searchValue.length === 0 || _ctx.state.isDisabled,
                      type: "submit",
                      onClick: _ctx.addExistingTransaction
                    }, {
                      default: _withCtx(() => [
                        (_ctx.state.isDisabled)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                              _createVNode(_component_b_spinner, {
                                small: "",
                                style: {"margin-top":"3px"}
                              }),
                              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.searching')) + "...", 1)
                            ]))
                          : _createCommentVNode("", true),
                        (!_ctx.state.isDisabled)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.go')), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"])
                  ]),
                  _: 1
                }, 8, ["onSubmit"])
              ]),
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.state.errorMessage), 1)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_b_button, { onClick: _ctx.addNewTransaction }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.addNew')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_b_button, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.state.searchExistingSelected=true))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.addExisting')), 1)
                ]),
                _: 1
              })
            ]))
      ]),
      _: 1
    }, 8, ["modelValue", "title", "onHide"])
  ]))
}