
import { defineComponent, reactive } from 'vue';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';
import BForm from '@/components/bootstrap-library/BForm.vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';

type State = {
    searchExistingSelected: boolean;
    errorMessage: string;
    isDisabled: boolean;
    searchValue: string;
    showModal: boolean;
}

export default defineComponent({
    name: 'add-transaction-modal',
    components: {
        BSpinner, BButton, BFormInput, BForm, BModal,
    },
    emits: ['addExistingTransaction', 'addNewTransaction'],
    setup(props, context) {
        const state = reactive<State>({
            errorMessage: '',
            isDisabled: false,
            searchExistingSelected: false,
            searchValue: '',
            showModal: false,
        });

        function close(): void {
            state.showModal = false;
            state.searchExistingSelected = false;
            state.searchValue = '';
            state.errorMessage = '';
        }

        function addNewTransaction(): void {
            context.emit('addNewTransaction');
            close();
        }

        async function addExistingTransaction() {
            state.isDisabled = true;

            context.emit('addExistingTransaction', parseInt(state.searchValue, 10));

            state.isDisabled = false;
            close();
        }

        function open() {
            state.showModal = true;
        }

        return {
            state,
            addNewTransaction,
            addExistingTransaction,
            close,
            open,
            getTranslation,
            getTitleCaseTranslation,
        };
    },
});

