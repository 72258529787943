
import { defineComponent, PropType } from 'vue';
import Transaction from '@/domain/Transaction';
import BButton from '@/components/bootstrap-library/BButton.vue';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';

export default defineComponent({
    name: 'shipping-truck-card',
    components: { BButton },
    props: {
        transaction: { type: Object as PropType<Transaction>, required: true },
        stopNo: { type: Number, required: true },
        selected: { type: Boolean, default: false },
        hasErrors: { type: Boolean, default: false },
    },
    emits: ['click'],
    setup(props, context) {
        function handleClick() {
            if (!props.selected) {
                context.emit('click');
            }
        }

        return {
            handleClick,
            getTranslation,
            getTitleCaseTranslation,
        };
    },
});
