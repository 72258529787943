const Emitter = require('tiny-emitter');

const emitter = new Emitter();

export default class EventBus {
    public $on(id: string, cb: Function) {
        return emitter.on(id, cb);
    }

    public $once(...args: any) {
        return emitter.once(...args);
    }

    public $off(...args: any) {
        return emitter.off(...args);
    }

    public $emit(id: string, data: any) {
        return emitter.emit(id, data);
    }
}
