import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "shipping-closeout-modal-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_modal, {
      modelValue: _ctx.state.showModal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.showModal) = $event)),
      centered: "",
      title: _ctx.state.modalTitle
    }, {
      footer: _withCtx(() => [
        (_ctx.state.isDownloading)
          ? (_openBlock(), _createBlock(_component_b_button, {
              key: 0,
              variant: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.downloading')) + " ", 1),
                _createVNode(_component_b_spinner, { small: "" })
              ]),
              _: 1
            }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_b_button, {
                variant: "light",
                onClick: _ctx.onCancel
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_b_button, {
                variant: "primary",
                onClick: _ctx.onOk
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.ok')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ], 64))
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", null, _toDisplayString(_ctx.state.modalBody), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "title"]),
    _createVNode(_component_b_dropdown, {
      title: _ctx.getTitleCaseTranslation('core.button.shippingActions'),
      "close-on-click": "",
      "drop-up": "",
      "no-caret": "",
      "open-on-hover": "",
      "is-button": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_b_dropdown_item, {
          disabled: _ctx.shipmentIsReadonly,
          onClick: _ctx.save
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.save')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"]),
        _createVNode(_component_b_dropdown_item, { onClick: _ctx.closeTrailer }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.closeTrailer')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_b_dropdown_item, {
          disabled: _ctx.shipmentIsReadonly || !_ctx.trailerClosed,
          onClick: _ctx.moveToYard
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.moveToYard')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"]),
        _createVNode(_component_b_dropdown_item, {
          disabled: !_ctx.trailerClosed || !_ctx.canShipNow,
          onClick: _ctx.shipNow
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.shipNow')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}