
import {
    defineComponent, onMounted, reactive, PropType,
} from 'vue';
import TransactionLine from '@/domain/TransactionLine';
import TransactionLineRow from '@/modules/floortrak/view/shared/components/TransactionLineRow.vue';
import { getTitleCaseTranslation } from '@/services/TranslationService';

type State = {
    showQuantityPlanned: boolean;
    enforceSingleContainer: boolean;
    modalType: string;
    showScanRequired: boolean;
}

export default defineComponent({

    name: 'transaction-line-table',
    components: { TransactionLineRow },
    props: {
        lineList: {
            type: Array as PropType<Array<TransactionLine>>,
            required: true,
        },
        tableType: {
            type: String,
            default: undefined,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },

    setup(props) {
        const state = reactive<State>({
            showQuantityPlanned: false,
            showScanRequired: false,
            enforceSingleContainer: false,
            modalType: 'Receiving',
        });

        function setRowPropsByTableType() {
            switch (props.tableType) {
            case 'receiving':
                state.showScanRequired = true;
                state.showQuantityPlanned = true;
                break;
            case 'shipping':
                state.showScanRequired = true;
                state.showQuantityPlanned = true;
                break;
            case 'inventory':
                break;
            default:
                break;
            }
        }

        onMounted(() => {
            setRowPropsByTableType();
        });

        return {
            state,
            getTitleCaseTranslation,
            setRowPropsByTableType,
        };
    },
});
