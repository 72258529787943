
import {
    defineComponent, onMounted, PropType,
} from 'vue';
import ShippingStore from '@/modules/floortrak/store/modules/ShippingStore';
import BButton from '@/components/bootstrap-library/BButton.vue';
import ShippingTruckCard from '@/modules/floortrak/view/shipping/components/ShippingTruckCard.vue';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';
import { ValidationResult } from '@/validation/types';
import Shipment from '@/domain/Shipment';

export default defineComponent({
    name: 'shipping-truck',
    components: {
        ShippingTruckCard, BButton,
    },
    props: {
        shippingStore: { type: Object as PropType<ShippingStore>, required: true },
        readonly: { type: Boolean, default: false },
        validationResult: {
            type: Object as PropType<ValidationResult<Shipment>>,
            default: undefined,
        },
    },
    emits: ['changeTransaction', 'addTransaction'],
    setup(props, context) {
        // eslint-disable-next-line global-require
        const flatbedImage = require('@/assets/rtf_trailer_transparent.png');

        onMounted(() => {
            document.getElementById(`shipping-truck-card-${props.shippingStore.activeIndex}`)?.scrollIntoView();
        });

        function changeTransaction(index: number) {
            context.emit('changeTransaction', index);
        }

        function getTransactionStopNumber(index: number): number {
            return index + 1;
        }

        function addTransaction() {
            context.emit('addTransaction');
        }

        function hasErrors(index: number): boolean {
            return props.validationResult?.model.transactions
                && props.validationResult?.model.transactions[index]
                && Object.keys(props.validationResult?.model.transactions[index]).length;
        }

        return {
            changeTransaction,
            addTransaction,
            getTransactionStopNumber,
            flatbedImage,
            getTranslation,
            getTitleCaseTranslation,
            hasErrors,
        };
    },
});
