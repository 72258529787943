import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9f73b5ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"flex"} }
const _hoisted_2 = { class: "tags-modal-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_thumbnail = _resolveComponent("thumbnail")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_quantity_picker = _resolveComponent("quantity-picker")!
  const _component_TrackedItemTagModal = _resolveComponent("TrackedItemTagModal")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_row = _resolveComponent("b-row")!

  return (_openBlock(), _createBlock(_component_b_row, { class: "item-row-container" }, {
    default: _withCtx(() => [
      _createVNode(_component_b_col, {
        cols: "1",
        style: {"margin":"auto"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_thumbnail, {
            "image-url-thumb": _ctx.line.item.imageUrlThumb,
            "image-url-full": _ctx.line.item.imageUrlFull
          }, null, 8, ["image-url-thumb", "image-url-full"])
        ]),
        _: 1
      }),
      _createVNode(_component_b_col, {
        "v-if": _ctx.line.item.floorLocation,
        class: "word-wrap-col",
        cols: "2"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.line.item.floorLocation), 1)
        ]),
        _: 1
      }, 8, ["v-if"]),
      _createVNode(_component_b_col, {
        class: "word-wrap-col",
        cols: "2"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.line.item.shortName), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_b_col, {
        class: "qty-input-col",
        cols: "4"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.showQuantityPlanned && _ctx.line.plannedQuantity)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass({'quantity-planned': true, 'quantity-met': _ctx.line.plannedQuantity === _ctx.line.actualQuantity})
                }, "(" + _toDisplayString(_ctx.line.plannedQuantity) + ")", 3))
              : _createCommentVNode("", true),
            (_ctx.enforceSingleContainer)
              ? (_openBlock(), _createBlock(_component_b_form_input, {
                  key: 1,
                  modelValue: _ctx.line.actualQuantity,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.line.actualQuantity) = $event)),
                  disabled: "",
                  style: {"width":"75px"}
                }, null, 8, ["modelValue"]))
              : (_openBlock(), _createBlock(_component_quantity_picker, {
                  key: _ctx.state.componentKey,
                  modelValue: _ctx.line.actualQuantity,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.line.actualQuantity) = $event)),
                  disabled: _ctx.lineIsReadonly,
                  class: "qty-picker",
                  onChange: _cache[2] || (_cache[2] = (qty) => _ctx.itemQtyChange(_ctx.line, qty))
                }, null, 8, ["modelValue", "disabled"])),
            _createElementVNode("div", _hoisted_2, [
              (_ctx.line.trackedItemList.length > 0)
                ? (_openBlock(), _createBlock(_component_TrackedItemTagModal, {
                    key: 0,
                    disabled: _ctx.lineIsReadonly,
                    tags: _ctx.line.trackedItemList,
                    title: _ctx.line.item.shortName,
                    onRemoveTag: _ctx.removeTag
                  }, null, 8, ["disabled", "tags", "title", "onRemoveTag"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", null, [
            (!_ctx.line.plannedQuantity)
              ? (_openBlock(), _createBlock(_component_b_button, {
                  key: 0,
                  class: "delete-row-button",
                  variant: "danger",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.deleteLine(_ctx.line)))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_faicon, { icon: "times" })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}