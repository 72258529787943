
import {
    defineComponent, onMounted, reactive, PropType,
} from 'vue';
import TransactionItemEventBus from '@/modules/floortrak/view/shared/transaction-item-event-bus';
import TransactionLine from '@/domain/TransactionLine';
import QuantityPicker from '@/components/QuantityPicker.vue';
import TrackedItemTagModal from '@/components/TrackedItemTagModal.vue';
import TrackedItem from '@/domain/TrackedItem';
import { useNotification } from '@/composable/useNotifications';
import BRow from '@/components/bootstrap-library/BRow.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';
import Thumbnail from '@/components/Thumbnail.vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import { getTranslation } from '@/services/TranslationService';

type State = {
    componentKey: number;
}

export default defineComponent({
    name: 'transaction-line-row',
    components: {
        BButton,
        Thumbnail,
        BCol,
        BRow,
        QuantityPicker,
        TrackedItemTagModal,
    },
    props: {
        line: {
            type: Object as PropType<TransactionLine>,
            required: true,
        },
        showScanRequired: {
            type: Boolean,
            required: false,
        },
        showQuantityPlanned: {
            type: Boolean,
            required: false,
        },
        enforceSingleContainer: {
            type: Boolean,
            required: false,
        },
        modalType: {
            type: String,
            required: true,
        },
        lineIsReadonly: {
            type: Boolean,
            default: false,
        },
    },

    setup(props) {
        const state = reactive<State>({
            componentKey: 0,
        });

        const notification = useNotification();

        onMounted(() => {
            // what is purpose of enforcing single container?
            if (props.enforceSingleContainer) {
                // state.actualQuantity = 1;
            }
        });

        function deleteLine(line: TransactionLine) {
            TransactionItemEventBus.$emit('REMOVE_LINE', line);
        }

        function removeTag(tag: TrackedItem) {
            TransactionItemEventBus.$emit('REMOVE_TAG', tag);
        }

        function itemQtyChange(line: TransactionLine, qty: number) {
            const tagsForItem = line.transactionLineDetails.length;
            if (qty < tagsForItem) {
                notification.showError(`${getTranslation('core.validation.qtyCannotBeLessThanTotalTagsForItem', qty)} (${tagsForItem})`);
            }
            state.componentKey++;
        }

        return {
            state,
            deleteLine,
            removeTag,
            itemQtyChange,
        };
    },

});

