import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31c21ea8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "logistics-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "logistics-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_shipping_truck_card = _resolveComponent("shipping-truck-card")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_button = _resolveComponent("b-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.flatbedImage,
      class: "truck-image",
      alt: "truck"
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shippingStore.transactionArray, (transaction, index) => {
        return (_openBlock(), _createBlock(_component_shipping_truck_card, {
          id: 'shipping-truck-card-' + index,
          key: index,
          transaction: transaction,
          "stop-no": _ctx.getTransactionStopNumber(index),
          selected: index === _ctx.shippingStore.activeIndex,
          "has-errors": _ctx.hasErrors(index),
          class: "shipping-logistics-card",
          onClick: ($event: any) => (_ctx.changeTransaction(index))
        }, null, 8, ["id", "transaction", "stop-no", "selected", "has-errors", "onClick"]))
      }), 128)),
      (_ctx.shippingStore.transactionArray.length <= 3 && !_ctx.readonly)
        ? (_openBlock(), _createBlock(_component_b_button, {
            key: 0,
            class: "shipping-logistics-button",
            variant: "secondary",
            onClick: _ctx.addTransaction
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.button.addStop')), 1),
              _createVNode(_component_faicon, {
                icon: "plus",
                size: "2x"
              })
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ])
  ]))
}